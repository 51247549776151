.nav-btn {
  display: flex;
  flex-direction: column;
  width: 1.4rem;
  gap: 7px;
  cursor: pointer;
  position: relative;
  z-index: 10000;
}
.nav-btn > span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0b72ba;
  transition: all ease 0.3s;
}
.nav-btn.active > span {
  background-color: white;
}

.nav-btn.active span:nth-child(2) {
  display: none;
}

.nav-btn.active > span:first-child {
  rotate: 135deg;
  position: absolute;
}
.nav-btn.active > span:last-child {
  rotate: -135deg;
}

@media (min-width: 992px) {
  .nav-btn {
    display: none;
  }
}
