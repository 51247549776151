.header.stikcy {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 4px 4px 14px rgba(11, 114, 186, 0.3);
  transition: background-color ease-in 0.4s;
  z-index: 100000;
}
/* Tailwind CSS classes */
.switch-label {
  font-size: 16px;
  margin-right: 10px;
}

.switch-container {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.switch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2196f3;
  position: absolute;
  transition: transform 0.3s;
}

.on {
  transform: translateX(30px);
}

.off {
  transform: translateX(0);
}
