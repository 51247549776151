.Container {
  width: min(100%, 1320px);
  margin-inline: auto;
}

@media (min-width: 1140px) {
  .Container {
    width: min(100%, 1440px);
  }
}
