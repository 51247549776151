@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (min-width: 578px) {
  .footer-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4.8rem;
  }
}

@media (min-width: 992px) {
  .footer-grid {
    display: grid;
    grid-template-columns: 2fr 1.5fr 2fr 2fr;
    gap: 1rem;
  }
}

.about-hero {
  background-image: url("./assests/images/About/about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.blur-background {
  backdrop-filter: blur(8px);
}
.blur-small-background {
  backdrop-filter: blur(0px);
}

.services-bg {
  background-image: url("./assests/images/services/open-account.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  .about-hero {
    background-position: top;
  }

  .services-bg {
    background-position: top;
  }
}
