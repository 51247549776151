 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{ margin-top:0cm;
	margin-right:0cm;
	margin-bottom:18.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}

span.topHeading {font-size:18.0pt; font-family :"Arial",sans-serif; color:#1F1F1F;} 
span.subHeading {font-size:12.0pt; font-family :"Arial",sans-serif; color:#1F1F1F; margin-bottom: 10pt;} 

@page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;} 
 /* List Definitions */
 ol
	{margin-bottom:0cm;}

ul.MsoNormal
	{margin-bottom:10pt; list-style-type: disc; margin-left: 20pt;} 

li.MsoNormal
    {font-size:12.0pt; font-family:"Arial",sans-serif}

a.MsoNormal
    {margin-bottom:.0001pt; font-style:italic; text-decoration: underline; font-size:12.0pt; font-family:"Arial",sans-serif; color:#0000fF}
